import React from "react"
import Layout from "../components/layout";
import FacebookSVG from "../assets/svg/facebook-f.svg";
import InstagramSVG from "../assets/svg/instagram.svg";
import YoutubeSVG from "../assets/svg/youtube.svg";
import SpotifySVG from "../assets/svg/spotify-color.svg";
import BookingForm from "../components/booking-form";
import Seo from "../components/seo";

const BoekenPage = () => {

    return (
        <Layout>
            <Seo title={"Boeken"} description={""} />
            <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
                <div className={"jdb--menu-spacer"} />
                <div className={"pb-2"}><h1 className={"h1"}>Boeken</h1></div>

                <div className={"w-full flex justify-center"}>
                    <BookingForm />
                </div>

                <div className={"w-full mt-10 bg-"}>
                    <div className={"w-full flex justify-center items-center space-x-10 mt-5"}>
                        <a href={"https://www.facebook.com/jandebruijnmusic"}
                           rel={"nofollow"}
                           target={"_blank"}
                           className={"inline-block"}>
                            <FacebookSVG className={"fill-black max-h-[40px] w-auto hover:fill-blue-400"} />
                        </a>

                        <a href={"https://www.instagram.com/jandebruijn3998/"}
                           rel={"nofollow"}
                           target={"_blank"}
                           className={"inline-block"}>
                            <InstagramSVG className={"fill-black max-h-[48px] w-auto hover:fill-blue-400"} />
                        </a>

                        <a href={"https://youtube.com/@jandebruijnmusic"}
                           rel={"nofollow"}
                           target={"_blank"}
                           className={"inline-block"}>
                            <YoutubeSVG className={"fill-black max-h-[48px] w-auto hover:fill-blue-400"} />
                        </a>

                        <a href={"https://open.spotify.com/artist/5MtM8nCW9bTS8IcRcMVNA9"}
                           rel={"nofollow"}
                           target={"_blank"}
                           className={"inline-block"}>
                            <SpotifySVG className={"fill-black max-h-[48px] w-auto hover:fill-blue-400"} />
                        </a>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default BoekenPage;
