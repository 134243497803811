import React from "react";
import { useForm, ValidationError } from "@formspree/react";

const BookingForm = () => {
    const [state, handleSubmit] = useForm("xgeqpqob");

    if (state.succeeded) {
        return (
            <div className={"w-full pt-10 font-secondary text-center"}>
                Bedankt! Je boekingsverzoek is ontvangen.
            </div>
        )
    }

    return (
        <form className="w-full max-w-lg pt-10 font-secondary" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label
                        className="block tracking-wide text-gray-700 font-bold mb-2"
                        htmlFor="grid-password">
                        E-mailadres
                    </label>
                    <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="email" type="email" name={"email"} required={true} />

                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label
                        className="block tracking-wide text-gray-700 font-bold mb-2"
                        htmlFor="grid-password">
                        Bericht
                    </label>
                    <textarea
                        className="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                        id="bericht" name={"bericht"}></textarea>
                    <p className="text-gray-600 text-xs italic">Informatie over het evenement</p>

                    <ValidationError prefix="Bericht" field="bericht" errors={state.errors} />
                </div>
            </div>
            <div className="md:flex md:items-center">
                <div className="md:w-1/3">
                    <button
                        className="jdb--button-blue-alternate focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                        type="submit" disabled={state.submitting}>
                        Versturen
                    </button>

                    <ValidationError errors={state.errors} />
                </div>
                <div className="md:w-2/3"></div>
            </div>
        </form>
    )
}

export default BookingForm;